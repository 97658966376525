import * as React from "react"

const Header = ({ siteTitle }) => {
  return (
    <header className="xl:float-left xl:sticky xl:top-0 xl:z-50 xl:h-0 xl:max:-top-64 xl:transition-[top] xl:duration-500 xl:ease-[ease] xl:delay-500 xl:max:delay-[0s] no-flick overflow-visible">
      <h1 className="xl:translate-x-11 2xl:translate-x-14 origin-left xl:p-0 xl:pt-11 2xl:pt-14 xl:text-[1.75rem] 2xl:text-[2.0625rem] text-[1.3rem]  p-10 xl:max-w-md 2xl:max-w-lg xl:max:max-w-[35ch] xl:max:translate-x-[10.75vw] tracking-[0.006em] leading-[1.4] xl:duration-500 xl:ease-[ease] xl:max:delay-500 xl:delay-[0s] xl:transition-[transform,max-width]">
        Hello, I’m&nbsp;
        <a
          href="mailto:cc@carsonchang.com"
          title="Send me an email"
          className="font-serif tracking-[-0.02em] relative left-[-0.03125em] group"
          style={{ wordSpacing: "-0.09375em" }}
        >
          <span className="pr-[0.09375em]">{siteTitle}</span> (
          <span className="icon-mail mx-[0.1875rem] xl:mx-1 overflow-hidden group-hover:overflow-visible text-[0.5625em]"></span>
          )
        </a>
        , a designer and <span className="whitespace-nowrap">front-end</span> developer building brands and digital experiences. Let’s&nbsp;
        <a
          href="https://linkedin.com/in/changcarson"
          title="LinkedIn"
          className="border-b border-dotted border-steel hover:border-none"
        >
          connect
        </a>
        .
      </h1>
    </header>
  )
}

export default Header
