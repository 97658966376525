import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Projects from "../components/projects"
import Header from "../components/header"
import "../styles/global.css"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const [max, setMax] = useState(false)
  const [still, setStill] = useState(true)

  const handleToggle = () => {
    setMax(!max)
    setStill(!still)
  }

  const handleTransitionEnd = () => {
    setStill(!still)
  }

  const loading = useRef(null)

  useEffect(() => {
    loading.current.classList.add("loaded")
  })

  return (
    <>
      <Helmet>
        <html
          lang="en"
          className={`text-[4.125vw] md:text-[3vw] xl:text-base 2xl:text-[1vw] bg-sky text-steel antialiased ${
            max ? "max" : ""
          } ${still ? "no-transition" : "pointer-events-none"}`}
        />

        <link rel="preload" as="image" href="/assets/noise.webp" />
        <link rel="preload" as="image" href="/template/mba.webp" />
        <link rel="preload" as="image" href="/template/iphone.webp" />
        <link rel="preload" as="font" href="/fonts/ag.woff2" />
        <link rel="preload" as="font" href="/fonts/agi.woff2" />
        <link rel="preload" as="font" href="/fonts/b.woff2" />
        <link rel="preload" as="font" href="/fonts/jp.woff2" />

        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="image" content="/assets/seo.webp" />

        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>💻</text></svg>"
        />
      </Helmet>

      <div
        ref={loading}
        className="loading fixed z-[999] w-full h-full flex justify-center items-center bg-sky"
      >
        <div className="loading-spinner"></div>
      </div>

      <Header siteTitle={data.site.siteMetadata.title} />
      <Projects />
      <footer className="p-10 xl:p-0 xl:fixed flex xl:bottom-[2.75rem] xl:left-[2.75rem] 2xl:bottom-14 2xl:left-14 items-center no-flick">
        <button
          className={`hidden xl:block relative z-40 icon-expand select-none cursor-pointer`}
          onClick={handleToggle}
          title={
            max ? "Click for standard layout" : "Click for maximized layout"
          }
        >
          <div>
            <div></div>
            <div></div>
          </div>
        </button>
        <div
          onTransitionEnd={handleTransitionEnd}
          className="text-[16px] xl:text-base xl:max:-rotate-90 xl:max:-translate-y-12 w-[20rem] xl:pl-6 origin-bottom-left xl:transition-transform xl:ease-[ease] xl:duration-1000"
        >
          Résumé available upon request.
          <br />
          Email:&nbsp;
          <a
            href="mailto:cc@carsonchang.com"
            title="Send me an email"
            className="border-b border-dotted border-steel hover:border-none"
          >
            cc@carsonchang.com
          </a>
        </div>
      </footer>
      <div className="w-full h-full fixed top-0 left-0 pointer-events-none z-[-1] after:content-[''] after:w-[calc(100%+20rem)] after:h-[calc(100%+20rem)] after:bg-center after:bg-[url('/assets/noise.webp')] after:absolute after:top-[-10rem] after:left-[-10rem] will-change-transform animate-[noise_1s_steps(2)_infinite]"></div>
    </>
  )
}
export default IndexPage
