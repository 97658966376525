import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import animateScrollTo from "animated-scroll-to"

const Projects = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
        edges {
          node {
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.edges

  const item = useRef([])

  item.current = projects.map((i) => item.current[i] ?? React.createRef())

  function expandItem(x) {
    if (x !== undefined) {
      let panel = item.current[x].current

      // panel.nextElementSibling.firstChild.classList.add("expand")
      panel.nextElementSibling.style.height =
        "calc(" +
        parseInt(panel.nextElementSibling.firstChild.scrollHeight) +
        "px*var(--max))"
      panel.nextElementSibling.style.maxHeight =
        panel.nextElementSibling.style.height
      // setTimeout(() => panel.scrollIntoView({ behavior: "smooth" }), 100)

      if (
        x !== 0 ||
        document.documentElement.clientWidth < 1180 ||
        document.documentElement.classList.contains("max")
      ) {
        animateScrollTo(panel, {
          speed: 2000,
          easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
          maxDuration: 400,
          minDuration: 300,
        }).then((hasScrolledToPosition) => {
          if (!hasScrolledToPosition) {
            animateScrollTo(panel, {
              speed: 2000,
              easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
              maxDuration: 400,
              minDuration: 300,
            })
          }
        })
      }
    } else {
      animateScrollTo(0, {
        speed: 2000,
        easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
        maxDuration: 400,
        minDuration: 300,
      }).then((hasScrolledToPosition) => {
        if (!hasScrolledToPosition) {
          animateScrollTo(0, {
            speed: 2000,
            easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
            maxDuration: 400,
            minDuration: 300,
          })
        }
      })
    }
  }

  return (
    <>
      <Accordion
        allowZeroExpanded
        onChange={(itemUuids) => {
          expandItem(itemUuids[0])
        }}
        className="xl:pt-11 2xl:pt-14 xl:pb-24 xl:max:translate-y-48 xl:transition-transform xl:ease-[ease] xl:duration-500 xl:delay-500 xl:max:delay-[0s]"
      >
        {projects.map((project, i) => (
          <AccordionItem
            key={i}
            uuid={i}
            className="relative xl:top-[3px] "
            // style={{ animationDelay: "calc(" + i + "*0.05s + 0.2s)" }}
          >
            <AccordionItemHeading className="sticky-heading sticky top-0 xl:max:-top-48 select-none z-40">
              <AccordionItemButton className="group pointer-events-none xl:w-[53.125vw] w-full xl:max:w-[85.8075vw] mx-auto xl:mr-0 xl:ml-auto xl:-translate-x-11 2xl:-translate-x-14">
                <div className="pointer-events-auto cursor-pointer xl:w-[53.125vw] w-[calc(100%-5rem)] mx-auto xl:ml-auto xl:mr-0 h-13 xl:h-[4.625rem] 2xl:h-[5rem] origin-top-right xl:max:scale-x-[161.52%]  z-30 after:absolute after:top-[-2px] after:left-[50%] after:translate-x-[-50%] after:pointer-events-none after:content-[''] after:w-[calc(100%-5rem)] xl:after:w-full after:h-full after:border-t after:border-t-steel xl:transition-transform xl:ease-[ease] xl:duration-500 xl:delay-[0s] xl:max:delay-500 text-[rgba(0,0,0,0)]">
                  {project.node.frontmatter.title}
                </div>
                <div className="arrow absolute top-0 2xl:top-[0.125rem] right-14 xl:right-6 origin-right after:absolute after:top-0 after:right-0 after:border-steel after:border-b after:border-r after:p-[0.375rem] xl:after:p-[0.5625rem] after:content-[''] after:translate-y-full after:rotate-45 group-aria-expanded:after:rotate-[-135deg] group-aria-expanded:after:translate-y-[160%] after:ease-[ease] after:duration-300 after:delay-[0s] xl:after:transition-transform xl:after:ease-[ease] xl:after:duration-500 xl:after:delay-[0s] xl:max:after:delay-500"></div>
              </AccordionItemButton>
            </AccordionItemHeading>

            {/* xl:max:scale-x-[161.52%] xl:max:before:scale-x-[61.91%]   xl:max:before:-translate-x-[0.4375rem] */}

            <div
              ref={item.current[i]}
              before={project.node.frontmatter.title}
              className="select-none before:origin-left before:content-[attr(before)]  before:duration-300 before:relative before:pr-6 before:leading-normal before:truncate before:top-[-1px] border-b-[2px] border-b-[rgba(0,0,0,0)] pointer-events-none font-serif text-[1.3rem] xl:text-[1.75rem] 2xl:text-[2.0625rem] text-steel tracking-[-0.02em] px-[3.5rem] xl:px-5 w-full xl:w-[53.125vw] h-13 xl:h-[4.625rem] 2xl:h-[5rem] xl:-translate-x-11 2xl:-translate-x-14 flex items-center xl:max:-translate-x-[36vw] 2xl:max:-translate-x-[36rem] origin-top-right absolute top-0 right-0 xl:transition-transform xl:ease-[ease] xl:duration-500 xl:delay-[0s] max:delay-500 before:transition-transform xl:before:ease-[ease] before:duration-500 xl:max:before:delay-500 xl:before:delay-[0s] truncate salt"
            ></div>

            <AccordionItemPanel
              className="group text-[16px] md:text-base xl:text-2xl 
                relative overflow-hidden"
            >
              <div
                className="overflow-visible fade-in panel no-flick px-10 py-6 md:px-[3.5rem] xl:px-[4rem] 2xl:px-[4.75rem] w-full xl:w-[calc(60.125vw)] mr-0 ml-auto xl:transition-[transform] xl:max:-translate-x-[4.90625vw] xl:ease-[ease] xl:duration-500 xl:delay-[0s] xl:max:delay-500 xl:origin-top-right xl:max:scale-[150%] leading-[1.4]"
                dangerouslySetInnerHTML={{ __html: project.node.html }}
              ></div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  )
}

export default Projects
